export default [
  {
    title: 'Dashboard',
    route: 'home'
  },
    {
        title: 'Stays',
        icon: 'ListIcon',
        children: [
            {
                title: 'All',
                route: 'stays/all'
            },
            {
                title: 'Upcoming',
                route: 'stays/upcoming'
            },
            {
                title: 'Current',
                route: 'stays/current'
            },
            {
                title: 'Past',
                route: 'stays/past'
            }
        ]
    },
    {
        title: 'Promotions',
        icon: 'StarIcon',
        route: 'home_screen_promotions'
    },
    {
        title: 'Digital signage',
        fa_icon: 'chalkboard',
        route: 'digital_signage_list'
    },
    {
        header: 'Items'
    }
]
