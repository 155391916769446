export default [
    {
        header: 'Configuration'
    },
    {
        title: 'Base items',
        icon: 'DatabaseIcon',
        children: [
            {
                title: 'Items',
                route: 'base_items'
            },
            {
                title: 'Design configs',
                route: 'base_items/design_configs'
            }
        ]
    },
    {
        title: 'Actions',
        route: 'actions',
        icon: 'OctagonIcon'
    },
    {
        title: 'Home Screen',
        icon: 'HomeIcon',
        children: [
            {
                title: 'Design config',
                route: 'home_screen/design_config'
            },
            {
                title: 'Config',
                route: 'home_screen/config'
            }
        ]
    },
    {
        title: 'Welcome Screen',
        fa_icon: 'door-open',
        children: [
            {
                title: 'Design config',
                route: 'welcome_screen/design_config'
            },
            {
                title: 'Config',
                route: 'welcome_screen/config'
            }
        ]
    },
    {
        title: 'Languages',
        route: 'languages',
        icon: 'GlobeIcon'
    },
    {
        title: 'Rooms',
        route: 'rooms',
        fa_icon: 'bed'
    },
    {
        title: 'STBs',
        route: 'stbs',
        icon: 'HardDriveIcon'
    },
    {
        title: 'Integrations',
        route: 'integrations',
        fa_icon: 'plug'
    },
    {
        title: 'TV',
        icon: 'MonitorIcon',
        children: [
            {
                title: 'Config',
                route: 'tv_channels/config'
            },
            {
                title: 'Channels',
                route: 'tv_channels'
            },
            {
                title: 'EPG',
                route: 'epg_providers'
            },
            {
                title: 'Categories',
                route: 'tv_channels/categories'
            }
        ]
    },
    {
        title: 'Reception panels',
        route: 'reception_panels',
        icon: 'TabletIcon'
    },
    {
        title: 'Config',
        icon: 'SettingsIcon',
        children: [
            {
                title: 'Banner',
                route: 'config/banner'
            },
            {
                title: 'Bill',
                route: 'config/bill'
            }
        ]
    },
    {
        header: 'Demo'
    },
    {
        title: 'Fake ERP',
        fa_icon: 'tasks',
        children: [
            {
                title: 'Stays',
                route: 'fake_erp/stays'
            },
            {
                title: 'Items',
                route: 'fake_erp/items'
            },
            {
                title: 'Reservation system',
                route: 'fake_erp/reservations'
            }
        ]
    }
]
