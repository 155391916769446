import Vue from 'vue'
import {ToastPlugin, ModalPlugin, VBTooltip} from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment-timezone'

import {library} from '@fortawesome/fontawesome-svg-core'
import {fas} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'


import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/toastification'
import axios from 'axios'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

//FontAwesome
library.add(fas)
Vue.component('fa', FontAwesomeIcon)

Vue.directive('b-tooltip', VBTooltip)

Vue.config.productionTip = false

Vue.prototype.moment = moment

Vue.prototype.$printError = function(error) {
    this.$toast({
        component: ToastificationContent,
        props: {
            title: 'Error',
            icon: 'DangerOctagonIcon',
            text: error,
            variant: 'danger'
        }
    })
}

Vue.prototype.$printWarning = function(message) {
    this.$toast({
        component: ToastificationContent,
        props: {
            title: 'Warning',
            icon: 'AlertTriangleIcon',
            text: message,
            variant: 'warning'
        }
    })
}

Vue.prototype.$printSuccess = function(message) {
    this.$toast({
        component: ToastificationContent,
        props: {
            title: 'Success',
            icon: 'CheckCircleIcon',
            text: message,
            variant: 'success'
        }
    })
}

const itemLoadPromise = axios.get('/api/management/v1/base_item').then(function(response) {

    for (let x = 0; x < response.data.length; x++) {
        store.dispatch('verticalMenu/addBaseItem', response.data[x])
    }

})

const pingPromise = axios.get('/api/management/v1/ping').then(function() {
    if (localStorage.userData) {
        store.dispatch('app/updateUserData', JSON.parse(localStorage.userData))
    }
    store.dispatch('app/setLoginStatus', true)

}).catch(function() {
    store.dispatch('app/setLoginStatus', false)
})

const integrationPromise = axios.get('/api/management/v1/integration').then(function(response) {
    store.dispatch('app/setEnabledIntegrations', response.data)
})

Promise.all([pingPromise, itemLoadPromise, integrationPromise]).finally(function() {

    new Vue({
        router,
        store,
        render: h => h(App)
    }).$mount('#app')

})
