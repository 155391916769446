import {$themeBreakpoints} from '@themeConfig'

export default {
    namespaced: true,
    state: {
        windowWidth: 0,
        shallShowOverlay: false,
        userData: {},
        loggedIn: false,
        enabledIntegrations: []
    },
    getters: {
        currentBreakPoint: state => {
            const {windowWidth} = state
            if (windowWidth >= $themeBreakpoints.xl) return 'xl'
            if (windowWidth >= $themeBreakpoints.lg) return 'lg'
            if (windowWidth >= $themeBreakpoints.md) return 'md'
            if (windowWidth >= $themeBreakpoints.sm) return 'sm'
            return 'xs'
        },
        userData: state => {
            return state.userData
        },
        loggedIn: state => {
            return state.loggedIn
        },
        integrationEnabled: state => integrationType => {
            return state.enabledIntegrations.some(integration => integration.type === integrationType)
        }
    },
    mutations: {
        UPDATE_WINDOW_WIDTH(state, val) {
            state.windowWidth = val
        },
        TOGGLE_OVERLAY(state, val) {
            state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
        },
        UPDATE_USER_DATA(state, data) {
            state.userData = data
        },
        SET_LOGIN_STATUS(state, status) {
            state.loggedIn = status
        },
        SET_ENABLED_INTEGRATIONS(state, status) {
            state.enabledIntegrations = status
        }
    },
    actions: {
        updateUserData({commit}, data) {
            commit('UPDATE_USER_DATA', data)
        },
        setLoginStatus({commit}, data) {
            commit('SET_LOGIN_STATUS', data)
        },
        setEnabledIntegrations({commit}, data) {
            commit('SET_ENABLED_INTEGRATIONS', data)
        }
    }
}
