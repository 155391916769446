import { $themeConfig } from '@themeConfig'
import startItems from '@/navigation/vertical/startItems'
import endItems from '@/navigation/vertical/endItems'
import axios from 'axios'
import router from '@/router'

export default {
  namespaced: true,
  state: {
    isVerticalMenuCollapsed: $themeConfig.layout.menu.isCollapsed,
    items: startItems.concat(endItems),
    customItems: []
  },
  getters: {},
  mutations: {
    UPDATE_VERTICAL_MENU_COLLAPSED(state, val) {
      state.isVerticalMenuCollapsed = val
    },
    UPDATE_CUSTOM_ITEMS(state) {
      state.items = startItems.concat(state.customItems).concat(endItems)
    },
    ADD_BASE_ITEM(state, item) {
      if (state.customItems.some((customItem) => customItem.id === item.id)) return


      if (item.icon_pack === 0) {
        state.customItems.push({
          id: item.id,
          title: item.name,
          icon: item.icon,
          children: [
            {
              title: 'Items',
              route: `base_item/${  item.id  }/items`
            },
            {
              title: 'Categories',
              route: `base_item/${  item.id  }/categories`
            }
          ]
        })
      } else {
        state.customItems.push({
          id: item.id,
          title: item.name,
          fa_icon: item.icon,
          children: [
            {
              title: 'Items',
              route: `base_item/${  item.id  }/items`
            },
            {
              title: 'Categories',
              route: `base_item/${  item.id  }/categories`
            }
          ]
        })
      }

      const routesToAdd = []

      routesToAdd.push({
        path: `/base_item/${  item.id  }/categories`,
        name: `base_item/${  item.id  }/categories`,
        component: () => import('@/views/GenericItem/Categories'),
        props: {
          baseItemId: item.id
        },
        meta: {
          pageTitle: item.name
        },
        breadcrumb: [
          {
            text: item.name,
            active: false
          },
          {
            text: 'Categories',
            active: true
          }
        ]
      })

      routesToAdd.push({
        path: `/base_item/${  item.id  }/items`,
        name: `base_item/${  item.id  }/items`,
        component: () => import('@/views/GenericItem/Items'),
        props: {
          baseItemId: item.id
        },
        meta: {
          pageTitle: item.name
        },
        breadcrumb: [
          {
            text: item.name,
            active: false
          },
          {
            text: 'Items',
            active: true
          }
        ]
      })

      routesToAdd.push({
        path: `/base_item/${ item.id}/item/:id`,
        name: `base_item/${ item.id}/item`,
        component: () => import('@/views/GenericItem/Item/Item'),
        props: {
          baseItemId: item.id
        },
        meta: {
          pageTitle: `${ item.name} item`
        }
      })

      router.addRoutes(routesToAdd)
    },
    REMOVE_BASE_ITEM(state, itemId) {

      const index = state.customItems.findIndex((customItem) => customItem.id === itemId)
      if (index === -1) return

      state.customItems.splice(index, 1)

    }
  },
  actions: {
    addBaseItem({ commit }, item) {
      commit('ADD_BASE_ITEM', item)
      commit('UPDATE_CUSTOM_ITEMS')
    },
    removeBaseItem({commit}, itemId) {
      commit('REMOVE_BASE_ITEM', itemId)
      commit('UPDATE_CUSTOM_ITEMS')
    },
    loadBaseItems({ commit }) {

      axios.get('/api/management/v1/base_item').then(function(response) {

        for (let x = 0; x < response.data.length; x++) {

          commit('ADD_BASE_ITEM', response.data[x])

        }

      }).finally(function() {
        commit('UPDATE_CUSTOM_ITEMS')
      })
    }
  }
}
