
const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('@/views/Home.vue'),
        meta: {
            pageTitle: 'Home',
            breadcrumb: [
                {
                    text: 'Home',
                    active: true
                }
            ]
        }
    },
    {
        path: '/languages',
        name: 'languages',
        component: () => import('@/views/Language/Languages'),
        meta: {
            pageTitle: 'Languages',
            breadcrumb: [
                {
                    text: 'Languages',
                    active: true
                }
            ]
        }
    },
    {
        path: '/rooms',
        name: 'rooms',
        component: () => import('@/views/Room/Rooms'),
        meta: {
            pageTitle: 'Rooms',
            breadcrumb: [
                {
                    text: 'Rooms',
                    active: true
                }
            ]
        }
    },
    {
        path: '/stbs',
        name: 'stbs',
        component: () => import('@/views/STB/STBs'),
        meta: {
            pageTitle: 'STBs',
            breadcrumb: [
                {
                    text: 'STBs',
                    active: true
                }
            ]
        }
    },
    {
        path: '/digital_signage_list',
        name: 'digital_signage_list',
        component: () => import('@/views/DigitalSignage/DigitalSignageList'),
        meta: {
            pageTitle: 'Digital Signage list',
            breadcrumb: [
                {
                    text: 'Digital Signage list',
                    active: true
                }
            ]
        }
    },
    {
        path: '/digital_signage/:id',
        name: 'digital_signage',
        component: () => import('@/views/DigitalSignage/DigitalSignage'),
        meta: {
            pageTitle: 'Digital Signage',
            breadcrumb: [
                {
                    text: 'Digital Signage',
                    active: true
                }
            ]
        }
    },
    {
        path: '/integrations',
        name: 'integrations',
        component: () => import('@/views/Integration/Integrations'),
        meta: {
            pageTitle: 'Integrations'
        }
    },
    {
        path: '/stays/all',
        name: 'stays/all',
        component: () => import('@/views/Stay/Stays'),
        props: {
            displayType: 'all'
        },
        meta: {
            pageTitle: 'Stays'
        }
    },
    {
        path: '/stays/upcoming',
        name: 'stays/upcoming',
        component: () => import('@/views/Stay/Stays'),
        props: {
            displayType: 'upcoming'
        },
        meta: {
            pageTitle: 'Stays'
        }
    },
    {
        path: '/stays/current',
        name: 'stays/current',
        component: () => import('@/views/Stay/Stays'),
        props: {
            displayType: 'current'
        },
        meta: {
            pageTitle: 'Stays'
        }
    },
    {
        path: '/stays/past',
        name: 'stays/past',
        component: () => import('@/views/Stay/Stays'),
        props: {
            displayType: 'past'
        },
        meta: {
            pageTitle: 'Stays'
        }
    },
    {
        path: '/home_screen_promotions',
        name: 'home_screen_promotions',
        component: () => import('@/views/HomeScreenPromotion/HomeScreenPromotions'),
        meta: {
            pageTitle: 'Home screen promotions'
        }
    },
    {
        path: '/tv_channels/config',
        name: 'tv_channels/config',
        component: () => import('@/views/TV/Config/Config'),
        meta: {
            pageTitle: 'TV config',
            breadcrumb: [
                {
                    text: 'TV',
                    active: true
                },
                {
                    text: 'Config',
                    active: true
                }
            ]
        }
    },
    {
        path: '/tv_channels',
        name: 'tv_channels',
        component: () => import('@/views/TV/Channels/TVChannels'),
        meta: {
            pageTitle: 'TV Channels',
            breadcrumb: [
                {
                    text: 'TV',
                    active: true
                },
                {
                    text: 'Channels',
                    active: true
                }
            ]
        }
    },
    {
        path: '/tv_channels/categories',
        name: 'tv_channels/categories',
        component: () => import('@/views/GenericItem/Categories'),
        props: {
            customRoute: '/api/management/v1/tv_channel_category'
        },
        meta: {
            pageTitle: 'TV config',
            breadcrumb: [
                {
                    text: 'TV',
                    active: true
                },
                {
                    text: 'Config',
                    active: true
                }
            ]
        }
    },
    {
        path: '/tv_channel/:id',
        name: 'tv_channel',
        component: () => import('@/views/TV/Channels/TVChannel'),
        meta: {
            pageTitle: 'TV Channel',
            breadcrumb: [
                {
                    text: 'TV',
                    active: true
                },
                {
                    text: 'Channel',
                    active: true
                }
            ]
        }
    },
    {
        path: '/epg_providers',
        name: 'epg_providers',
        component: () => import('@/views/TV/EPG/EPGProviders'),
        meta: {
            pageTitle: 'EPG Providers',
            breadcrumb: [
                {
                    text: 'TV',
                    active: true
                },
                {
                    text: 'EPG Providers',
                    active: true
                }
            ]
        }
    },
    {
        path: '/epg_provider/:id',
        name: 'epg_provider',
        component: () => import('@/views/TV/EPG/EPGProvider'),
        meta: {
            pageTitle: 'EPG Provider',
            breadcrumb: [
                {
                    text: 'TV',
                    active: true
                },
                {
                    text: 'EPG Provider',
                    active: true
                }
            ]
        }
    },
    {
        path: '/base_items',
        name: 'base_items',
        component: () => import('@/views/BaseItem/BaseItems'),
        meta: {
            pageTitle: 'Base items',
            breadcrumb: [
                {
                    text: 'Base items',
                    active: false
                },
                {
                    text: 'Items',
                    active: true
                }
            ]
        }
    },
    {
        path: '/base_items/design_configs',
        name: 'base_items/design_configs',
        component: () => import('@/views/BaseItem/DesignConfig'),
        meta: {
            pageTitle: 'Base items',
            breadcrumb: [
                {
                    text: 'Base items',
                    active: false
                },
                {
                    text: 'Design config',
                    active: true
                }
            ]
        }
    },
    {
        path: '/config/banner',
        name: 'config/banner',
        component: () => import('@/views/Banner/Banner'),
        meta: {
            pageTitle: 'Banner config',
            breadcrumb: [
                {
                    text: 'Config',
                    active: true
                },
                {
                    text: 'Banner',
                    active: true
                }
            ]
        }
    },
    {
        path: '/home_screen/design_config',
        name: 'home_screen/design_config',
        component: () => import('@/views/HomeScreen/HomeScreenDesignConfig'),
        meta: {
            pageTitle: 'Home Screen design config',
            breadcrumb: [
                {
                    text: 'Home Screen',
                    active: true
                },
                {
                    text: 'Design Config',
                    active: true
                }
            ]
        }
    },
    {
        path: '/home_screen/config',
        name: 'home_screen/config',
        component: () => import('@/views/HomeScreen/HomeScreenConfig'),
        meta: {
            pageTitle: 'Home Screen config',
            breadcrumb: [
                {
                    text: 'Home Screen',
                    active: true
                },
                {
                    text: 'Config',
                    active: true
                }
            ]
        }
    },
    {
        path: '/welcome_screen/design_config',
        name: 'welcome_screen/design_config',
        component: () => import('@/views/WelcomeScreen/WelcomeScreenDesignConfig'),
        meta: {
            pageTitle: 'Welcome Screen design config',
            breadcrumb: [
                {
                    text: 'Welcome Screen',
                    active: true
                },
                {
                    text: 'Design Config',
                    active: true
                }
            ]
        }
    },
    {
        path: '/welcome_screen/config',
        name: 'welcome_screen/config',
        component: () => import('@/views/WelcomeScreen/WelcomeScreenConfig'),
        meta: {
            pageTitle: 'Welcome Screen config',
            breadcrumb: [
                {
                    text: 'Welcome Screen',
                    active: true
                },
                {
                    text: 'Config',
                    active: true
                }
            ]
        }
    },
    {
        path: '/config/bill',
        name: 'config/bill',
        component: () => import('@/views/Bill/Config'),
        meta: {
            pageTitle: 'Bill config',
            breadcrumb: [
                {
                    text: 'Config',
                    active: true
                },
                {
                    text: 'Bill',
                    active: true
                }
            ]
        }
    },
    {
        path: '/fake_erp/stays',
        name: 'fake_erp/stays',
        component: () => import('@/views/FakeERP/Stays/Stays'),
        meta: {
            pageTitle: 'Stays'
        }
    },
    {
        path: '/fake_erp/items',
        name: 'fake_erp/items',
        component: () => import('@/views/FakeERP/Items/Items'),
        meta: {
            pageTitle: 'Items'
        }
    },
    {
        path: '/fake_erp/reservations',
        name: 'fake_erp/reservations',
        component: () => import('@/views/FakeERP/Reservations/Reservations'),
        meta: {
            pageTitle: 'Reservations'
        }
    },
    {
        path: '/reception_panels',
        name: 'reception_panels',
        component: () => import('@/views/ReceptionPanels/ReceptionPanels'),
        meta: {
            pageTitle: 'Reception panels',
            breadcrumb: [
                {
                    text: 'Reception panels',
                    active: true
                }
            ]
        }
    },
    {
        path: '/actions',
        name: 'actions',
        component: () => import('@/views/Actions/Actions'),
        meta: {
            pageTitle: 'Actions',
            breadcrumb: [
                {
                    text: 'Actions',
                    active: true
                }
            ]
        }
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/Login.vue'),
        meta: {
            layout: 'full'
        }
    },
    {
        path: '/error-404',
        name: 'error-404',
        component: () => import('@/views/error/Error404.vue'),
        meta: {
            layout: 'full'
        }
    },
    {
        path: '*',
        redirect: 'error-404'
    }
]

/*function addItemRoutes(item) {

    routes.push(
        {
            path: `/${ item.type}/items`,
            name: `${ item.type}/items`,
            component: () => import('@/views/GenericItem/Items'),
            props: {
                itemType: item.type
            },
            meta: {
                pageTitle: `${ item.name} items`
            }
        },
        {
            path: `/${ item.type}/config`,
            name: `${ item.type}/config`,
            component: () => import('@/views/GenericItem/Config'),
            props: {
                itemType: item.type
            },
            meta: {
                pageTitle: `${ item.name} config`
            }
        },
        {
            path: `/${ item.type}/categories`,
            name: `${ item.type}/categories`,
            component: () => import('@/views/GenericItem/Categories'),
            props: {
                itemType: item.type
            },
            meta: {
                pageTitle: `${ item.name} categories`,
                breadcrumb: [
                    {
                        text: item.name,
                        active: true
                    },
                    {
                        text: 'Categories',
                        active: true
                    }
                ]
            }
        },
        {
            path: `/${ item.type}/:id`,
            name: `${ item.type}/item`,
            component: () => import('@/views/GenericItem/Item/Item'),
            props: {
                itemType: item.type
            },
            meta: {
                pageTitle: `${ item.name} item`
            }
        }
    )
}

const itemTypes = [
    {
        type: 'special_offer',
        name: 'Special offer'
    },
    {
        type: 'hotel_service',
        name: 'Hotel service'
    },
    {
        type: 'shop_item',
        name: 'Shop'
    },
    {
        type: 'room_service',
        name: 'Room service'
    },
    {
        type: 'massage_item',
        name: 'Massage'
    },
    {
        type: 'local_info',
        name: 'Local info'
    },
    {
        type: 'dining_item',
        name: 'Dining'
    },
    {
        type: 'facility_item',
        name: 'Facility'
    },
    {
        type: 'useful_info',
        name: 'Useful info'
    },
    {
        type: 'game_item',
        name: 'Game'
    },
    {
        type: 'app_item',
        name: 'App'
    }
]

for (let x = 0; x < itemTypes.length; x++) {
    addItemRoutes(itemTypes[x])
}*/

export default routes
