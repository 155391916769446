import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import routes from './routes'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
    routes
})

router.beforeEach((to, from, next) => {
    if (to.path === 'reception_panel') {
        return next()
    } else if (!store.getters['app/loggedIn'] && to.path !== '/login') {
        return next('/login')
    } else if (store.getters['app/loggedIn'] && to.path === '/login') {
        return next('/')
    }

    next()
})

export default router
